/**
 * Deals Grid (DealsPage')
 * @description : Grid of Deals, filterable
 */


var modules = document.querySelectorAll(".js-EventPage");
// The main module Vue instance

var vueActivityModules = [];

for (var i = 0, max = modules.length; i < max; i++) {
    (function () {

        vueActivityModules[i] = new Vue({
            el: modules[i],

            // TODO: Fetch data from Umbraco API
            data: {
                id: 0,
                selected: false,
                cookieName: 'saved-events',
                cookieDayLength: 365
            }, 
            methods: {
                setCookie: function (name, value, daysToLive) {
                    var cookie = name + "=" + encodeURIComponent(value);
                    console.log(cookie);
                    if (typeof daysToLive === "number") {
                        cookie += "; max-age=" + (daysToLive * 24 * 60 * 60) + "; path=/;";
                        document.cookie = cookie;
                    }
                },
                getCookie: function (name) {
                    var cookieArr = document.cookie.split(";");
                    for (var i = 0; i < cookieArr.length; i++) {
                        var cookiePair = cookieArr[i].split("=");
                        if (name === cookiePair[0].trim()) {
                            return decodeURIComponent(cookiePair[1]);
                        }
                    }
                    return null;
                },
                manageEvent: function (e) {
                    var cookie = this.getCookie(this.cookieName);
                    var savedEvents = cookie ? cookie.split(',') : [];
                    // Remove any spurious empty values
                    savedEvents = savedEvents.filter(Boolean);

                    if (!this.selected) {
                        // event is currently saved - remove from saved
                        var self = this;
                        savedEvents = savedEvents.filter(function (event) {
                            return event !== self.id;
                        });
                        this.setCookie(this.cookieName, savedEvents.join(','), this.cookieDayLength);

                        console.log('event removed from saved. Cookie value: ' + this.getCookie(this.cookieName));

                    } else {
                        // event is not saved - let's save it!
                        if (savedEvents.indexOf(this.id) === -1) {
                            // New event ID to be added to the cookie
                            savedEvents.push(this.id);
                            this.setCookie(this.cookieName, savedEvents.join(','), this.cookieDayLength);
                        }

                        console.log('event saved! Cookie value: ' + this.getCookie(this.cookieName));

                    }

                }
            },
            mounted: function () {
                var self = this;
                self.id = id;
                var cookie = self.getCookie(self.cookieName);
                var savedEvents = cookie ? cookie.split(',') : [];
                var index = savedEvents.indexOf(id.toString());
                self.selected = index > -1;
            }
        });

    })();
}